@import '~antd/dist/antd.css';
iframe#webpack-dev-server-client-overlay{display:none!important}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.row-selected {
    font-weight: bold !important;
    background-color: #DCF4FF !important;
}

.centered-spinner {
    left: 50%; /*half the container width*/
    margin-left: -250px; /*half the width of the image*/
}
.contact-detail-card-content {
    display: flex;
    column-gap: 30px;
}

.icon-block-left {
    order: 1;
}

.icon-block-right {
    order: 3;
}


.contact-detail {
    padding-left: 20px;
    order: 2;
}

.contact-detail + .icon-block-right {
    padding-left: 30px;
    height: 200px;
    border-left: 1px solid rgb(164, 164, 164,.5);
}

.contact-detail + .icon-block-left{
    padding-right: 30px;
    height: 200px;
    border-right: 1px solid rgb(164, 164, 164,.5);
}


@media screen and (max-width: 1440px) {
    .contact-detail-card-content {
        display: flex;
        justify-content: center;
        column-gap: 10px;
        row-gap: 10px;
        flex-direction: column;
    }

    .icon-block-right, .icon-block-left {
        order: 1;
    }

    .contact-detail {
        order: 2;
    }

    .contact-detail + .icon-block-left{
        border-right: 0; height:  auto;
    }

    .contact-detail + .icon-block-right {
        border-left: 0;
        height:  auto;

    }


}


