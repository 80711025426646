.ant-statistic.darwin .ant-statistic-title {
    color: white;
    font-size: 1.1rem;
    font-weight: 700;
}

.ant-statistic.darwin .ant-statistic-content {
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
}