.timeTag {
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.timeTag-gray {
    border: 1px solid gray;
    background-color: #efefef;
}

.timeTag-orange {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
}

.timeTag-green {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
}

.timeTag-blue {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
}

.timeTag-red {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
}


.time_tag_value {
    word-break: break-word;
}